export interface IBaseEntity {
  __v?: number;
  _id: string;
  createdAt: Date;
  updatedAt: Date;
}

export enum ENTITY_STATUS {
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE',
  HIDDEN = 'HIDDEN',
  DELETED = 'DELETED',
}

export enum ENTITY_SORT {
  ASC = 'asc',
  DESC = 'desc',
}

export enum CREATED_FROM {
  POS = 'POS',
  LITE_POS = 'LITE_POS',
}

export enum HIDDEN_ON {
  POS = 'POS',
  KIOSK = 'KIOSK',
  WEB = 'WEB',
}

export enum DISH_STATUS {
  AVAILABLE = 'AVAILABLE',
  UNAVAILABLE = 'UNAVAILABLE',
  UNAVAILABLE_FOR_TODAY = 'UNAVAILABLE_FOR_TODAY',
  HIDDEN = 'HIDDEN',
  DELETED = 'DELETED',
}
