import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import debounce from 'lodash/debounce';
import truncate from 'lodash/truncate';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Brunch from '../../../assets/svgs/brunch.svg';
import { useConfirmationPopup } from '../../../components/AppConfirmationPopup';
import AppEmptyTable from '../../../components/AppEmptyTable';
import { SNACKBARTYPE, useSnackbar } from '../../../components/AppSnackbar';
import AppTable from '../../../components/AppTable';
import { IAppTableRow } from '../../../components/AppTable/types/table.types';
import {
  useAppDispatch,
  useAppSelector,
  usePaginationState,
} from '../../../hooks';
import { selectMenuPagination } from '../../../redux/selectors/menuPaginationSelectors';
import { selectPages } from '../../../redux/selectors/pagesSelectors';
import { ACTION_KEYS, PAGES_COLUMNS } from '../constants';
import ViewLayout from '../hocs/ViewLayout';
import {
  disableMenuPagination,
  setMenuPaginationLimit,
  setMenuPaginationSkip,
} from '../redux/menuPaginationSlice';
import {
  deletePage,
  fetchPageData,
  patchUpdatePage,
  updatePageSequenceNumbers,
} from '../redux/pagesSlice';
import { ENTITY_SORT, ENTITY_STATUS } from '../types/common.types';
import { IPagesTable } from '../types/pages.types';
import { getFormattedPages } from '../utils/formatting.utils';

const PagesPage = () => {
  const { openConfirmationPopup } = useConfirmationPopup();
  const dispatch = useAppDispatch();
  const { openSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { pagesData, refreshing, extras } = useAppSelector(selectPages);
  const { search_key: searchKey } = useAppSelector(selectMenuPagination);

  const [isShowAll, setIsShowAll] = useState(false);

  const { onChange, pageIndex, pageSize, total } = usePaginationState({
    total: extras.total,
    pageSize: extras.limit ?? 10,
    pageIndex:
      (extras.skip ?? 0) > 0
        ? Math.floor((extras.skip ?? 0) / (extras.limit ?? 0))
        : 0,
  });

  const handleOnCreate = () => {
    navigate('/menu/pages/create');
  };

  const fetchPageDataCallback = useCallback(async () => {
    if (isShowAll) {
      dispatch(disableMenuPagination());
    } else {
      dispatch(setMenuPaginationSkip(pageIndex * pageSize));
      dispatch(setMenuPaginationLimit(pageSize));
    }
    await dispatch(
      fetchPageData({ sort_by: 'sequenceNumber', sort_order: ENTITY_SORT.ASC }),
    );
  }, [dispatch, isShowAll, pageIndex, pageSize]);

  useEffect(() => {
    fetchPageDataCallback();
  }, [fetchPageDataCallback]);

  const formattedPagesData = useMemo(() => {
    return pagesData.map((page) => getFormattedPages(page));
  }, [pagesData]);

  const handlePreviousClick = () => onChange(pageIndex - 1);
  const handleNextClick = () => onChange(pageIndex + 1);

  const debouncedHandleOnClickAction = debounce(
    async (key: string, data: IAppTableRow<IPagesTable>) => {
      const foundItem = pagesData.find((item) => item._id === data.key);
      switch (key) {
        case ACTION_KEYS.AVAILABLE: {
          const response = await dispatch(
            patchUpdatePage({
              id: data.key,
              body: {
                status: ENTITY_STATUS.AVAILABLE,
              },
            }),
          );

          if (response.meta.requestStatus === 'fulfilled') {
            openSnackbar(
              'Page status has been updated Successfully!',
              SNACKBARTYPE.SUCCESS,
            );
          }
          await fetchPageDataCallback();
          break;
        }
        case ACTION_KEYS.DELETE: {
          openConfirmationPopup({
            color: 'error',
            heading: `Delete ${truncate(foundItem?.name, { length: 20 })}`,
            content: (
              <Box>
                <Typography component="span" sx={{ fontWeight: 600 }}>
                  {foundItem?.name}
                </Typography>
                {' will no longer be in the pages will be deleted permanently'}
              </Box>
            ),
            confirmButtonTitle: 'Delete',
            onConfirm: async () => {
              const response = await dispatch(deletePage(data.key));
              if (response.meta.requestStatus === 'fulfilled') {
                openSnackbar(
                  'Page has been deleted Successfully!',
                  SNACKBARTYPE.SUCCESS,
                );
              }
              await fetchPageDataCallback();
              onChange(0);
            },
          });

          break;
        }

        case ACTION_KEYS.HIDE: {
          openConfirmationPopup({
            heading: `Hide ${truncate(foundItem?.name, { length: 20 })}`,
            content: (
              <Box>
                <Typography component="span" sx={{ fontWeight: 600 }}>
                  {foundItem?.name}
                </Typography>
                {' will be hidden from the pages'}
              </Box>
            ),
            confirmButtonTitle: 'Hide',
            onConfirm: async () => {
              const response = await dispatch(
                patchUpdatePage({
                  id: data.key,
                  body: {
                    status: ENTITY_STATUS.HIDDEN,
                  },
                }),
              );
              if (response.meta.requestStatus === 'fulfilled') {
                openSnackbar(
                  'Page has been hidden Successfully!',
                  SNACKBARTYPE.SUCCESS,
                );
              }
              await fetchPageDataCallback();
            },
          });

          break;
        }
        case ACTION_KEYS.UNAVAILABLE: {
          openConfirmationPopup({
            heading: `Unavailable ${truncate(foundItem?.name, { length: 20 })}`,
            content: (
              <Box>
                <Typography component="span" sx={{ fontWeight: 600 }}>
                  {foundItem?.name}
                </Typography>
                {
                  ' will be Unavailable and will no longer have access in the pages'
                }
              </Box>
            ),
            confirmButtonTitle: 'Unavailable',
            onConfirm: async () => {
              const response = await dispatch(
                patchUpdatePage({
                  id: data.key,
                  body: {
                    status: ENTITY_STATUS.UNAVAILABLE,
                  },
                }),
              );
              if (response.meta.requestStatus === 'fulfilled') {
                openSnackbar(
                  'Page status has been updated to Unavailable Successfully!',
                  SNACKBARTYPE.SUCCESS,
                );
              }
              await fetchPageDataCallback();
            },
          });
          break;
        }
        case ACTION_KEYS.EDIT:
          handleOnClickRow(data);
          break;
        default:
          break;
      }
    },
    500,
    { leading: true, trailing: false },
  );

  const handleOnRowDrag = async (dragId: string, dropId: string) => {
    await dispatch(
      updatePageSequenceNumbers({
        dragId,
        dropId,
      }),
    );
  };

  const handleOnClickRow = async (data: IAppTableRow<IPagesTable>) => {
    navigate(`/menu/pages/edit/${data.key}`);
  };

  const handleShowAllResultsClick = () => {
    if (isShowAll) {
      dispatch(disableMenuPagination());
    }
    setIsShowAll((prevState) => !prevState);
  };

  return (
    <ViewLayout
      title="Pages"
      onClickCreate={handleOnCreate}
      buttonText="Create Page"
      hideButton={formattedPagesData.length === 0}>
      <AppTable
        key={searchKey}
        refreshing={refreshing}
        handleNextClick={handleNextClick}
        handlePreviousClick={handlePreviousClick}
        enablePagination={false}
        pagination={{
          total: total,
          pageIndex: pageIndex,
          pageSize: pageSize,
          showAllStatus: isShowAll,
        }}
        columns={PAGES_COLUMNS}
        rows={formattedPagesData}
        enableRowActions
        onClickActions={debouncedHandleOnClickAction}
        onClickRow={handleOnClickRow}
        emptyComponent={
          <AppEmptyTable
            title="No Pages Added"
            icon={<img src={Brunch} alt="Brunch" />}
            primaryButton={{
              title: 'Create Page',
              startIcon: <AddIcon />,
              onClick: handleOnCreate,
            }}
          />
        }
        onDragDropChange={!searchKey ? handleOnRowDrag : undefined}
        enableShowAllResultsButton
        handleShowAllResultsClick={handleShowAllResultsClick}
      />
    </ViewLayout>
  );
};

export default PagesPage;
