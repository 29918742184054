import compact from 'lodash/compact';
import truncate from 'lodash/truncate';

import {
  APP_LIST_COLUMN_TYPES,
  IAppListRow,
} from '../../../components/AppList/types/list.types';
import {
  APP_COLUMN_TYPES,
  IAppTableRow,
} from '../../../components/AppTable/types/table.types';
import { Colors } from '../../../theme/colors';
import { ACTION_KEYS } from '../constants';
import {
  IAddOn,
  IAddOnGroupsResponseDTO,
  IAddOnGroupsTable,
  IAddOnTable,
} from '../types/add-on-groups.types';
import {
  CategoriesResponseDTO,
  ICategoriesTable,
} from '../types/categories.types';
import {
  IChoice,
  IChoiceGroupsResponseDTO,
  IChoiceGroupsTable,
  IChoiceTable,
} from '../types/choice-groups.types';
import { DISH_STATUS, ENTITY_STATUS } from '../types/common.types';
import {
  ICreateDishAddonGroupList,
  ICreateDishChoiceGroupList,
  IDishesResponseDTO,
  IDishesTable,
} from '../types/dishes.types';
import {
  ICreatePageCategoriesList,
  ICreatePageDishesList,
  IPagesResponseDTO,
  IPagesTable,
} from '../types/pages.types';
import {
  ISubCategoriesTable,
  SubCategoryResponseDTO,
} from '../types/subCategory.types';

export const getFormattedCategories = (category: CategoriesResponseDTO) => {
  const row: IAppTableRow<ICategoriesTable> = {
    key: category._id,
    name: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: truncate(category.name, { length: 30 }),
      },
    },
    itemsCount: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value:
          category.itemsCount === 1
            ? `${category.itemsCount} item`
            : `${category.itemsCount} items`,
      },
    },
    status: {
      type: APP_COLUMN_TYPES.CHIP,
      data: {
        value: getStatusText(category.status),
        ...getStatusChipData(category.status),
      },
    },
    subRows: [],
    actions: [],
  };

  switch (category.status) {
    case ENTITY_STATUS.AVAILABLE:
      row.actions.push({
        key: ACTION_KEYS.UNAVAILABLE,
        name: 'Mark as Unavailable',
      });
      row.actions.push({
        key: ACTION_KEYS.HIDE,
        name: 'Hide',
      });
      break;

    case ENTITY_STATUS.UNAVAILABLE:
      row.actions.push({
        key: ACTION_KEYS.AVAILABLE,
        name: 'Mark as Available',
      });
      row.actions.push({
        key: ACTION_KEYS.HIDE,
        name: 'Hide',
      });
      break;

    case ENTITY_STATUS.HIDDEN:
      row.actions.push({
        key: ACTION_KEYS.AVAILABLE,
        name: 'Mark as Available',
      });
      break;

    default:
      break;
  }

  row.actions.push({
    key: ACTION_KEYS.EDIT,
    name: 'Edit',
  });

  row.actions.push({
    key: ACTION_KEYS.DELETE,
    name: 'Delete',
    fontColor: Colors.error,
  });

  return row;
};

export const getFormattedSubCategory = (
  subCategory: SubCategoryResponseDTO,
) => {
  const row: IAppTableRow<ISubCategoriesTable> = {
    key: subCategory._id,
    name: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: truncate(subCategory.name, { length: 30 }),
      },
    },
    itemsCount: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value:
          subCategory.itemsCount === 1
            ? `${subCategory.itemsCount} item`
            : `${subCategory.itemsCount} items`,
      },
    },
    status: {
      type: APP_COLUMN_TYPES.CHIP,
      data: {
        value: getStatusText(subCategory.status),
        ...getStatusChipData(subCategory.status),
      },
    },
    subRows: [],
    actions: [],
  };

  switch (subCategory.status) {
    case ENTITY_STATUS.AVAILABLE:
      row.actions.push({
        key: ACTION_KEYS.UNAVAILABLE,
        name: 'Mark as Unavailable',
      });
      row.actions.push({
        key: ACTION_KEYS.HIDE,
        name: 'Hide',
      });
      break;

    case ENTITY_STATUS.UNAVAILABLE:
      row.actions.push({
        key: ACTION_KEYS.AVAILABLE,
        name: 'Mark as Available',
      });
      row.actions.push({
        key: ACTION_KEYS.HIDE,
        name: 'Hide',
      });
      break;

    case ENTITY_STATUS.HIDDEN:
      row.actions.push({
        key: ACTION_KEYS.AVAILABLE,
        name: 'Mark as Available',
      });
      break;

    default:
      break;
  }

  row.actions.push({
    key: ACTION_KEYS.EDIT,
    name: 'Edit',
  });

  row.actions.push({
    key: ACTION_KEYS.DELETE,
    name: 'Delete',
    fontColor: Colors.error,
  });

  return row;
};

export const getFormattedChoiceGroups = (
  choiceGroup: IChoiceGroupsResponseDTO,
) => {
  const maxVisibleChoiceItems = 4;
  const choiceItemNames = choiceGroup.choices.map((choice) => choice.name);
  const visibleChoiceItemNames = choiceItemNames.slice(
    0,
    maxVisibleChoiceItems,
  );
  const additionalChoiceItemCount =
    choiceItemNames.length - maxVisibleChoiceItems;

  const row: IAppTableRow<IChoiceGroupsTable> = {
    key: choiceGroup._id,
    name: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: truncate(choiceGroup.name, { length: 30 }),
      },
    },
    choiceItems: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value:
          visibleChoiceItemNames.join(', ') +
          (additionalChoiceItemCount > 0
            ? `... ${additionalChoiceItemCount} more`
            : ''),
      },
    },
    required: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: choiceGroup.min ? 'Yes' : 'No',
      },
    },
    minQty: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: choiceGroup.min ? `${choiceGroup.min}` : '-',
      },
    },
    maxQty: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: choiceGroup.max ? `${choiceGroup.max}` : '-',
      },
    },
    status: {
      type: APP_COLUMN_TYPES.CHIP,
      data: {
        value: getStatusText(choiceGroup.status),
        ...getStatusChipData(choiceGroup.status),
      },
    },
    subRows: [],
    actions: [],
  };

  switch (choiceGroup.status) {
    case ENTITY_STATUS.AVAILABLE:
      row.actions.push({
        key: ACTION_KEYS.UNAVAILABLE,
        name: 'Mark as Unavailable',
      });
      row.actions.push({
        key: ACTION_KEYS.HIDE,
        name: 'Hide',
      });
      break;

    case ENTITY_STATUS.UNAVAILABLE:
      row.actions.push({
        key: ACTION_KEYS.AVAILABLE,
        name: 'Mark as Available',
      });
      row.actions.push({
        key: ACTION_KEYS.HIDE,
        name: 'Hide',
      });
      break;

    case ENTITY_STATUS.HIDDEN:
      row.actions.push({
        key: ACTION_KEYS.AVAILABLE,
        name: 'Mark as Available',
      });
      break;

    default:
      break;
  }

  row.actions.push({
    key: ACTION_KEYS.EDIT,
    name: 'Edit',
  });

  row.actions.push({
    key: ACTION_KEYS.DELETE,
    name: 'Delete',
    fontColor: Colors.error,
  });

  return row;
};

export const getFormattedChoiceItems = (choice: IChoice, currency: string) => {
  const row: IAppTableRow<IChoiceTable> = {
    key: choice._id,
    name: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: choice.name,
      },
    },
    price: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: choice.price?.toString() ?? '0',
        currency: `${currency} `,
        isCent: true,
      },
    },
    status: {
      type: APP_COLUMN_TYPES.CHIP,
      data: {
        value: getStatusText(choice.status),
        ...getStatusChipData(choice.status),
      },
    },
    subRows: [],
    actions: [],
  };

  switch (choice.status) {
    case ENTITY_STATUS.AVAILABLE:
      row.actions.push({
        key: ACTION_KEYS.UNAVAILABLE,
        name: 'Mark as Unavailable',
      });
      row.actions.push({
        key: ACTION_KEYS.HIDE,
        name: 'Hide',
      });
      break;

    case ENTITY_STATUS.UNAVAILABLE:
      row.actions.push({
        key: ACTION_KEYS.AVAILABLE,
        name: 'Mark as Available',
      });
      row.actions.push({
        key: ACTION_KEYS.HIDE,
        name: 'Hide',
      });
      break;

    case ENTITY_STATUS.HIDDEN:
      row.actions.push({
        key: ACTION_KEYS.AVAILABLE,
        name: 'Mark as Available',
      });
      break;

    default:
      break;
  }

  row.actions.push({
    key: ACTION_KEYS.EDIT,
    name: 'Edit',
  });

  row.actions.push({
    key: ACTION_KEYS.DELETE,
    name: 'Delete',
    fontColor: Colors.error,
  });

  return row;
};

export const getFormattedPages = (page: IPagesResponseDTO) => {
  const row: IAppTableRow<IPagesTable> = {
    key: page._id,
    name: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: truncate(page.name, { length: 30 }),
      },
    },
    status: {
      type: APP_COLUMN_TYPES.CHIP,
      data: {
        value: getStatusText(page.status ?? ENTITY_STATUS.AVAILABLE),
        ...getStatusChipData(page.status ?? ENTITY_STATUS.AVAILABLE),
      },
    },
    subRows: [],
    actions: [],
  };

  switch (page.status) {
    case ENTITY_STATUS.AVAILABLE:
      row.actions.push({
        key: ACTION_KEYS.HIDE,
        name: 'Hide',
      });
      break;

    case ENTITY_STATUS.HIDDEN:
      row.actions.push({
        key: ACTION_KEYS.AVAILABLE,
        name: 'Available',
      });
      break;

    default:
      break;
  }

  row.actions.push({
    key: ACTION_KEYS.EDIT,
    name: 'Edit',
  });

  row.actions.push({
    key: ACTION_KEYS.DELETE,
    name: 'Delete',
    fontColor: Colors.error,
  });

  return row;
};

export const getFormattedDishes = (
  dish: IDishesResponseDTO,
  currency: string,
) => {
  const row: IAppTableRow<IDishesTable> = {
    key: dish._id,
    name: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: truncate(dish.name, { length: 30 }),
      },
    },
    category: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: compact(dish.categories)
          .map((category) => category.name)
          .sort()
          .join(', '),
      },
    },
    price: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: dish.price?.toString() ?? '0',
        currency: `${currency} `,
        isCent: true,
      },
    },
    status: {
      type: APP_COLUMN_TYPES.CHIP,
      data: {
        value: getDishStatusText(dish.status ?? DISH_STATUS.AVAILABLE),
        ...getStatusChipData(dish.status ?? DISH_STATUS.AVAILABLE),
      },
    },
    subRows: [],
    actions: [],
  };

  switch (dish.status) {
    case DISH_STATUS.AVAILABLE:
      row.actions.push({
        key: ACTION_KEYS.UNAVAILABLE,
        name: 'Mark as Unavailable',
      });
      row.actions.push({
        key: ACTION_KEYS.HIDE,
        name: 'Hide',
      });
      break;

    case DISH_STATUS.UNAVAILABLE:
      row.actions.push({
        key: ACTION_KEYS.AVAILABLE,
        name: 'Mark as Available',
      });
      row.actions.push({
        key: ACTION_KEYS.HIDE,
        name: 'Hide',
      });
      break;

    case DISH_STATUS.HIDDEN:
      row.actions.push({
        key: ACTION_KEYS.AVAILABLE,
        name: 'Mark as Available',
      });
      break;

    default:
      break;
  }

  row.actions.push({
    key: ACTION_KEYS.EDIT,
    name: 'Edit',
  });

  row.actions.push({
    key: ACTION_KEYS.DELETE,
    name: 'Delete',
    fontColor: Colors.error,
  });

  return row;
};

export const getFormattedAddOnGroups = (
  addOnGroup: IAddOnGroupsResponseDTO,
) => {
  const maxVisibleAddOns = 5;
  const addOnNames = addOnGroup.addons.map((addon) => addon.name);
  const visibleAddOnNames = addOnNames.slice(0, maxVisibleAddOns);
  const additionalAddOnCount = addOnNames.length - maxVisibleAddOns;

  const row: IAppTableRow<IAddOnGroupsTable> = {
    key: addOnGroup._id,
    name: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: truncate(addOnGroup.name, { length: 30 }),
      },
    },
    addOnItems: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value:
          visibleAddOnNames.join(', ') +
          (additionalAddOnCount > 0 ? `... ${additionalAddOnCount} more` : ''),
      },
    },
    status: {
      type: APP_COLUMN_TYPES.CHIP,
      data: {
        value: getStatusText(addOnGroup.status),
        ...getStatusChipData(addOnGroup.status),
      },
    },
    subRows: [],
    actions: [],
  };

  switch (addOnGroup.status) {
    case ENTITY_STATUS.AVAILABLE:
      row.actions.push({
        key: ACTION_KEYS.UNAVAILABLE,
        name: 'Mark as Unavailable',
      });
      row.actions.push({
        key: ACTION_KEYS.HIDE,
        name: 'Hide',
      });
      break;

    case ENTITY_STATUS.UNAVAILABLE:
      row.actions.push({
        key: ACTION_KEYS.AVAILABLE,
        name: 'Mark as Available',
      });
      row.actions.push({
        key: ACTION_KEYS.HIDE,
        name: 'Hide',
      });
      break;

    case ENTITY_STATUS.HIDDEN:
      row.actions.push({
        key: ACTION_KEYS.AVAILABLE,
        name: 'Mark as Available',
      });
      break;

    default:
      break;
  }

  row.actions.push({
    key: ACTION_KEYS.EDIT,
    name: 'Edit',
  });

  row.actions.push({
    key: ACTION_KEYS.DELETE,
    name: 'Delete',
    fontColor: Colors.error,
  });

  return row;
};

export const getFormattedAddOnItems = (addOn: IAddOn, currency: string) => {
  const row: IAppTableRow<IAddOnTable> = {
    key: addOn._id,
    name: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: addOn.name,
      },
    },
    price: {
      type: APP_COLUMN_TYPES.CURRENCY,
      data: {
        value: addOn.price?.toString() ?? '0',
        currency: `${currency} `,
        isCent: true,
      },
    },
    max: {
      type: APP_COLUMN_TYPES.TEXT,
      data: {
        value: addOn.max ? `${addOn.max}` : '-',
      },
    },
    status: {
      type: APP_COLUMN_TYPES.CHIP,
      data: {
        value: getStatusText(addOn.status),
        ...getStatusChipData(addOn.status),
      },
    },
    subRows: [],
    actions: [],
  };

  switch (addOn.status) {
    case ENTITY_STATUS.AVAILABLE:
      row.actions.push({
        key: ACTION_KEYS.UNAVAILABLE,
        name: 'Mark as Unavailable',
      });
      row.actions.push({
        key: ACTION_KEYS.HIDE,
        name: 'Hide',
      });
      break;

    case ENTITY_STATUS.UNAVAILABLE:
      row.actions.push({
        key: ACTION_KEYS.AVAILABLE,
        name: 'Mark as Available',
      });
      row.actions.push({
        key: ACTION_KEYS.HIDE,
        name: 'Hide',
      });
      break;

    case ENTITY_STATUS.HIDDEN:
      row.actions.push({
        key: ACTION_KEYS.AVAILABLE,
        name: 'Mark as Available',
      });
      break;

    default:
      break;
  }

  row.actions.push({
    key: ACTION_KEYS.EDIT,
    name: 'Edit',
  });

  row.actions.push({
    key: ACTION_KEYS.DELETE,
    name: 'Delete',
    fontColor: Colors.error,
  });

  return row;
};

export const getFormattedDishCreationChoiceGroups = (
  choiceGroup: IChoiceGroupsResponseDTO,
) => {
  const row: IAppListRow<ICreateDishChoiceGroupList> = {
    key: choiceGroup._id,
    name: {
      type: APP_LIST_COLUMN_TYPES.TEXT,
      data: {
        value: choiceGroup.name,
      },
    },
    required: {
      type: APP_LIST_COLUMN_TYPES.CHIP,
      data: {
        value: choiceGroup.min ? 'Required' : 'Optional',
        backgroundColor: Colors.chipSuccessBackground,
        color: Colors.chipSuccessText,
      },
    },
    minQty: {
      type: APP_LIST_COLUMN_TYPES.CHIP,
      data: {
        value: choiceGroup.min ? `Min Qty ${choiceGroup.min}` : 'Min Qty 0',
        backgroundColor: Colors.chipWarningBackground,
        color: Colors.chipWarningText,
      },
    },
    maxQty: {
      type: APP_LIST_COLUMN_TYPES.CHIP,
      data: {
        value: choiceGroup.max ? `Max Qty ${choiceGroup.max}` : 'Max Qty 0',
        backgroundColor: Colors.chipInfoBackground,
        color: Colors.chipInfoText,
      },
    },
    choices: {
      type: APP_LIST_COLUMN_TYPES.LIST,
      data: { value: choiceGroup.choices },
    },

    actions: [],
  };

  return row;
};

export const getFormattedDishCreationAddonGroups = (
  addonGroup: IAddOnGroupsResponseDTO,
) => {
  const row: IAppListRow<ICreateDishAddonGroupList> = {
    key: addonGroup._id,
    name: {
      type: APP_LIST_COLUMN_TYPES.TEXT,
      data: {
        value: addonGroup.name,
      },
    },
    maxQty: {
      type: APP_LIST_COLUMN_TYPES.CHIP,
      data: {
        value: addonGroup.max ? `Max Qty ${addonGroup.max}` : 'Max Qty 0',
        backgroundColor: Colors.chipInfoBackground,
        color: Colors.chipInfoText,
      },
    },
    addons: {
      type: APP_LIST_COLUMN_TYPES.LIST,
      data: { value: addonGroup.addons },
    },
    subRows: [],
    actions: [],
  };

  return row;
};

export const getFormattedPageCreationCategories = (
  category: CategoriesResponseDTO,
) => {
  const row: IAppListRow<ICreatePageCategoriesList> = {
    key: category._id,
    name: {
      type: APP_LIST_COLUMN_TYPES.TEXT,
      data: {
        value: category.name,
      },
    },

    actions: [],
  };

  return row;
};

export const getFormattedPageCreationDishes = (dish: IDishesResponseDTO) => {
  const row: IAppListRow<ICreatePageDishesList> = {
    key: dish._id,
    name: {
      type: APP_LIST_COLUMN_TYPES.TEXT,
      data: {
        value: dish.name,
      },
    },

    actions: [],
  };

  return row;
};

const getStatusChipData = (
  value: string,
): {
  color?: string;
  backgroundColor?: string;
} => {
  switch (value) {
    case 'AVAILABLE':
      return {
        backgroundColor: Colors.chipSuccessBackground,
        color: Colors.chipSuccessText,
      };

    case 'UNAVAILABLE':
      return {
        backgroundColor: Colors.chipFailedBackground,
        color: Colors.chipFailedText,
      };

    default:
      return {
        backgroundColor: Colors.chipDefaultBackground,
        color: Colors.chipDefaultText,
      };
  }
};

const getStatusText = (status: string): string => {
  switch (status) {
    case ENTITY_STATUS.AVAILABLE:
      return 'Available';
    case ENTITY_STATUS.UNAVAILABLE:
      return 'Unavailable';
    case ENTITY_STATUS.HIDDEN:
      return 'Hidden';
    case ENTITY_STATUS.DELETED:
      return 'Deleted';
    default:
      return 'Unknown';
  }
};

const getDishStatusText = (status: string): string => {
  switch (status) {
    case DISH_STATUS.AVAILABLE:
      return 'Available';
    case DISH_STATUS.UNAVAILABLE:
      return 'Unavailable';
    case DISH_STATUS.HIDDEN:
      return 'Hidden';
    case DISH_STATUS.UNAVAILABLE_FOR_TODAY:
      return 'Unavailable for today';
    case DISH_STATUS.DELETED:
      return 'Deleted';
    default:
      return 'Unknown';
  }
};
